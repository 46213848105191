{
  "ascending": "nouseva",
  "ascendingSort": "lajiteltu sarakkeen {columnName} mukaan nousevassa järjestyksessä",
  "columnSize": "{value} pikseliä",
  "descending": "laskeva",
  "descendingSort": "lajiteltu sarakkeen {columnName} mukaan laskevassa järjestyksessä",
  "select": "Valitse",
  "selectAll": "Valitse kaikki",
  "sortable": "lajiteltava sarake"
}
