{
  "select": "Select",
  "selectAll": "Select All",
  "sortable": "sortable column",
  "ascending": "ascending",
  "descending": "descending",
  "ascendingSort": "sorted by column {columnName} in ascending order",
  "descendingSort": "sorted by column {columnName} in descending order",
  "columnSize": "{value} pixels"
}
