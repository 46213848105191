{
  "ascending": "didėjančia tvarka",
  "ascendingSort": "surikiuota pagal stulpelį {columnName} didėjančia tvarka",
  "columnSize": "{value} piks.",
  "descending": "mažėjančia tvarka",
  "descendingSort": "surikiuota pagal stulpelį {columnName} mažėjančia tvarka",
  "select": "Pasirinkti",
  "selectAll": "Pasirinkti viską",
  "sortable": "rikiuojamas stulpelis"
}
