{
  "ascending": "stigende",
  "ascendingSort": "sortert etter kolonne {columnName} i stigende rekkefølge",
  "columnSize": "{value} piksler",
  "descending": "synkende",
  "descendingSort": "sortert etter kolonne {columnName} i synkende rekkefølge",
  "select": "Velg",
  "selectAll": "Velg alle",
  "sortable": "kolonne som kan sorteres"
}
