{
  "ascending": "artan sırada",
  "ascendingSort": "{columnName} sütuna göre artan düzende sırala",
  "columnSize": "{value} piksel",
  "descending": "azalan sırada",
  "descendingSort": "{columnName} sütuna göre azalan düzende sırala",
  "select": "Seç",
  "selectAll": "Tümünü Seç",
  "sortable": "Sıralanabilir sütun"
}
