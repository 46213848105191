{
  "ascending": "aufsteigend",
  "ascendingSort": "sortiert nach Spalte {columnName} in aufsteigender Reihenfolge",
  "columnSize": "{value} Pixel",
  "descending": "absteigend",
  "descendingSort": "sortiert nach Spalte {columnName} in absteigender Reihenfolge",
  "select": "Auswählen",
  "selectAll": "Alles auswählen",
  "sortable": "sortierbare Spalte"
}
