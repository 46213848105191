{
  "ascending": "vzestupně",
  "ascendingSort": "řazeno vzestupně podle sloupce {columnName}",
  "columnSize": "{value} pixelů",
  "descending": "sestupně",
  "descendingSort": "řazeno sestupně podle sloupce {columnName}",
  "select": "Vybrat",
  "selectAll": "Vybrat vše",
  "sortable": "sloupec s možností řazení"
}
