{
  "ascending": "croissant",
  "ascendingSort": "trié en fonction de la colonne {columnName} par ordre croissant",
  "columnSize": "{value} pixels",
  "descending": "décroissant",
  "descendingSort": "trié en fonction de la colonne {columnName} par ordre décroissant",
  "select": "Sélectionner",
  "selectAll": "Sélectionner tout",
  "sortable": "colonne triable"
}
